<template>
  <div
    class="product-item border-gray-800 rounded-2xl p-2 flex justify-between items-center max-w-full"
  >
    <router-link
      class="product-link"
      :to="{ name: 'product', params: { id: data.id } }"
    >
      <div class=" inline-block">
        <img
          :src="data.name.cover_url"
          class="rounded hover:h-12 hover:shadow-2xl"
        />
      </div>

      <div class="text-gray-900 font-light inline-block py-1">
        {{ data.name.title }}
      </div>
    </router-link>
    <div
      v-if="!disableActions"
      class="delete-btn"
      @click="$emit('deleteProduct', data.id)"
    >
      {{ isProductDeletionPending ? 'delete in progress...' : 'delete' }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: Object,
    index: Number,
    isProductDeletionPending: Boolean,
    disableActions: Boolean
  },
  computed: {
    cover_url() {
      return `http://covers.openlibrary.org/b/id/${this.data.cover_i}-L.jpg`
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';

.product-item {
  .product-link {
    color: $vue-color;
  }

  img {
    height: auto;
    max-width: 4rem;
    margin-right: 1rem;
  }

  .delete-btn {
    cursor: pointer;
    padding: 5px 10px;
    border: 1px solid;
    display: inline-block;
    border-radius: 3px;
    margin-left: 10px;
    color: $danger-color;
    border-color: $danger-color;
  }
}
</style>
