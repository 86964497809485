<template>
  <div class=" mb-24">
    <p v-if="products === null" class="infos-label">Loading...</p>
    <div class="mb-10">
      <p
        v-if="products && !products.length"
        class="infos-label infos-label font-regular text-blue-900 bg-blue-300 mx-32 rounded-full "
      >
        You don't have any books yet.
      </p>
      <p
        v-if="products && products.length > 0 && products.length != 4"
        class="infos-label font-regular text-yellow-900 bg-yellow-300 mx-32 rounded-full "
      >
        You have <span class="font-bold">{{ products.length }}</span> of
        <span class="font-bold">4</span> books.
      </p>
      <p
        v-if="products && products.length > 0 && products.length === 4"
        class="infos-label font-regular text-green-900 bg-green-300 mx-32 rounded-full "
      >
        <span class="font-bold">Beautiful!</span> Scroll down to generate your
        background!
      </p>
    </div>

    <div v-if="products && (products.length < 4 || !products.length)">
      <add-product v-if="networkOnLine"></add-product>
    </div>

    <div class="mt-8">
      <product-item
        v-for="(product, index) in products"
        :key="product.id"
        class="product-row"
        :index="index"
        :is-product-deletion-pending="isProductDeletionPending(product.id)"
        :disable-actions="!networkOnLine"
        :data="product"
        @deleteProduct="deleteUserProduct"
      ></product-item>
    </div>
  </div>
</template>

<script>
import ProductItem from '@/components/ProductItem'
import { mapState, mapActions, mapGetters } from 'vuex'
import AddProduct from './AddProduct.vue'

export default {
  components: { ProductItem, AddProduct },
  computed: {
    ...mapGetters('products', ['isProductDeletionPending']),
    ...mapState('products', ['products']),
    ...mapState('app', ['networkOnLine'])
  },
  methods: mapActions('products', ['deleteUserProduct'])
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';

.infos-label {
  text-align: center;
}

.product-row {
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 500px;
  margin: 10px auto;
  justify-content: space-between;
}
</style>
