<template>
  <div class="py-8 rounded-xl shadow-lg min-h-1 bg-white">
    <div class="product-action-bar ">
      <div
        class=" cursor-pointer px-5 py-2 inline-block rounded-lg bg-indigo-600 text-white uppercase font-bold shadow-lg hover:bg-indigo-500"
        @click="setBookShelfUrl"
      >
        Generate Image
      </div>
      <div>
        <a
          :href="bookshelfImageUrl"
          class=" uppercase px-4 text-indigo-900 font-extrabold tracking-wider hover:text-indigo-300 cursor-pointer"
          @click.prevent="downloadItem(bookshelfImageUrl)"
          >Download</a
        >
      </div>
    </div>
    <div style="height=1280 width=720">
      <img :src="bookshelfImageUrl" class="mx-auto mt-4" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import axios from 'axios'

export default {
  components: {},
  computed: {
    ...mapState('products', ['bookshelfImageUrl', 'products'])
  },
  methods: {
    ...mapMutations('products', ['updateBookShelfUrl']),
    ...mapActions('products', ['setBookShelfUrl']),
    downloadItem(url) {
      axios({
        url,
        method: 'GET',
        responseType: 'blob'
      })
        .then(response => {
          const fileURL = window.URL.createObjectURL(new Blob([response.data]))
          const fileLink = document.createElement('a')

          fileLink.href = fileURL
          fileLink.setAttribute('download', 'bookshelf-background.png')
          document.body.appendChild(fileLink)

          fileLink.click()
        })
        .catch(console.error)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';

.product-action-bar {
  display: flex;
  align-items: center;
  justify-content: center;

  .product-name-input {
    padding-left: 5px;
    height: 30px;
    width: 400px;
    font: inherit;
  }

  .create-product-btn {
    cursor: pointer;
    padding: 5px 10px;
    border: 1px solid;
    display: inline-block;
    border-radius: 3px;
    margin-left: 10px;
    border-color: #2c3e50;
    background-color: $do-color;
    color: white;

    &.disabled {
      pointer-events: none;
      background-color: #aaa;
    }

    &:hover {
      background-color: $vue-color;
      border-color: $vue-color;
    }
  }
}
img {
  padding: 10px 10px;
  height: auto;
  max-width: 90%;
}
</style>
