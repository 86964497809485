<template>
  <div>
    <v-select
      class="bg-white shadow-lg"
      label="title"
      :filterable="false"
      :options="options"
      :value="$store.state.productNameToCreate"
      @input="setProductNameToCreate"
      @search="onSearch"
    >
      <template slot="no-options">
        <span class="text-gray-900">
          Type to search book covers...
        </span>
      </template>
      <template slot="option" slot-scope="option">
        <div class="d-center">
          <img class="vimage" :src="option.cover_url" />
          {{ option.title }} -
          {{ option.author }}
        </div>
      </template>
      <template slot="selected-option" slot-scope="option">
        <div class="selected d-center">
          <img :src="option.cover_url" />
          {{ option.title }} ({{ option.author }})
        </div>
      </template>
    </v-select>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex'
import vSelect from 'vue-select'
import _ from 'lodash'

export default {
  components: {
    vSelect
  },
  data: () => ({
    options: []
  }),
  computed: mapState('products', [
    'productNameToCreate',
    'productCreationPending'
  ]),
  methods: {
    ...mapMutations('products', ['setProductNameToCreate']),
    ...mapActions('products', ['triggerAddProductAction']),
    onSearch(search, loading) {
      loading(true)
      this.search(loading, search, this)
    },
    search: _.debounce((loading, search, vm) => {
      fetch(`https://openlibrary.org/search.json?q=${escape(search)}`).then(
        res => {
          console.log(`https://openlibrary.org/search.json?q=${escape(search)}`)
          res.json().then(
            json =>
              (vm.options = json.docs
                .filter(book => book.cover_i)
                .map(book => {
                  return {
                    title: book.title,
                    cover: book.cover_i,
                    author: book.author_name
                      ? book.author_name.join(', ')
                      : 'Unlabeled',
                    cover_url: `https://covers.openlibrary.org/b/id/${book.cover_i}-M.jpg`,
                    fullPayload: book
                  }
                }))
          )
          loading(false)
        }
      )
    }, 500)
  }
}
</script>

<style lang="scss">
@import 'vue-select/src/scss/vue-select.scss';
.vimage {
  height: auto;
  max-width: 4rem;
  margin-right: 1rem;
}

.d-center {
  display: flex;
  align-items: center;
}

.selected img {
  width: auto;
  max-height: 23px;
  margin-right: 0.5rem;
}

.v-select .dropdown li {
  border-bottom: 1px solid rgba(112, 128, 144, 0.1);
}

.v-select .dropdown li:last-child {
  border-bottom: none;
}

.v-select .dropdown li a {
  padding: 10px 20px;
  width: 100%;
  font-size: 1.25em;
  color: #3c3c3c;
  max-height: 100px;
}

.v-select .dropdown-menu .active > a {
  color: #fff;
}
</style>
