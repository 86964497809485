<template>
  <div class="page-wrapper max-w-2xl">
    <h1 class="products-page-title">Bookshelf Background Creator</h1>
    <div class="mx-auto mt-10">
      <product-list
        class="product-list border-solid bg-white rounded-lg shadow-lg border-gray-900 stroke-3 py-10 max-w-1/2"
      ></product-list>
    </div>

    <div class=" mt-4 py-5">
      <div v-if="products && products.length === 4" class=" mx-auto my-auto">
        <generate-image></generate-image>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ProductList from '@/components/ProductList'
import GenerateImage from '@/components/GenerateImage.vue'

export default {
  components: { ProductList, GenerateImage },
  computed: {
    ...mapState('products', ['products']),
    ...mapState('app', ['networkOnLine'])
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';

.products-page-title {
  text-align: center;
  margin-bottom: 60px;
}

.product-list {
  margin: 20px 0;
}
</style>
