<template>
  <div class="product-action-bar shal">
    <autocomplete-test class="product-name-input"></autocomplete-test>
    <div
      :class="{ disabled: productCreationPending }"
      class="hover:shadow-inner cursor-pointer py-2 px-5 inline-block rounded-lg ml-4 shadow-lg bg-indigo-600 text-white uppercase font-bold"
      @click="triggerAddProductAction"
    >
      Add book
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex'
import AutocompleteTest from './AutocompleteTest.vue'

export default {
  components: { AutocompleteTest },
  computed: mapState('products', [
    'productNameToCreate',
    'productCreationPending'
  ]),
  methods: {
    ...mapMutations('products', ['setProductNameToCreate']),
    ...mapActions('products', ['triggerAddProductAction'])
  }
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';

.product-action-bar {
  display: flex;
  align-items: center;
  justify-content: center;

  .product-name-input {
    padding-left: 5px;
    height: 30px;
    width: 400px;
    font: inherit;
  }

  .create-product-btn {
    cursor: pointer;
    padding: 5px 10px;
    border: 1px solid;
    display: inline-block;
    border-radius: 3px;
    margin-left: 10px;
    border-color: #2c3e50;

    &.disabled {
      pointer-events: none;
      background-color: #aaa;
    }

    &:hover {
      color: $vue-color;
      border-color: $vue-color;
    }
  }
}
</style>
